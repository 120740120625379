"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KnownRunners = exports.Employee = exports.ComponentRunnerCategory = void 0;
/** @TranslateToPython */
var ComponentRunnerCategory;
(function (ComponentRunnerCategory) {
    ComponentRunnerCategory["income"] = "Income";
    ComponentRunnerCategory["underwriting"] = "Underwriting";
    ComponentRunnerCategory["payments"] = "Payments";
    ComponentRunnerCategory["cardHolder"] = "CardHolder";
    ComponentRunnerCategory["creditPolicy"] = "CreditPolicy";
    ComponentRunnerCategory["metrics"] = "Metrics";
    ComponentRunnerCategory["pendingDisputes"] = "pendingDisputes";
    ComponentRunnerCategory["compliance"] = "Compliance";
    ComponentRunnerCategory["communications"] = "Communications";
    ComponentRunnerCategory["facebookPixel"] = "FacebookPixel";
    ComponentRunnerCategory["googleTagManager"] = "GoogleTagManager";
    ComponentRunnerCategory["tikTokPixel"] = "TikTokPixel";
    ComponentRunnerCategory["utmTags"] = "UtmTags";
    ComponentRunnerCategory["payItForward"] = "payItForward";
    ComponentRunnerCategory["accountOpening"] = "AccountOpening";
    ComponentRunnerCategory["mobileApp"] = "mobileApp";
    ComponentRunnerCategory["dataIntegrity"] = "dataIntegrity";
    ComponentRunnerCategory["notary"] = "notary";
    ComponentRunnerCategory["capitalMarkets"] = "capitalMarkets";
    ComponentRunnerCategory["origination"] = "origination";
    ComponentRunnerCategory["contingencies"] = "contingencies";
    ComponentRunnerCategory["logs"] = "logs";
})(ComponentRunnerCategory = exports.ComponentRunnerCategory || (exports.ComponentRunnerCategory = {}));
/** @TranslateToPython */
var Employee;
(function (Employee) {
    Employee["ABED_ISLAM"] = "ABED_ISLAM";
    Employee["ANIRUDDH_IYENGAR"] = "ANIRUDDH_IYENGAR";
    Employee["AMOR_MENDOZA"] = "AMOR_MENDOZA";
    Employee["ANDREI_NATIVIDAD"] = "ANDREI_NATIVIDAD";
    Employee["ANDREW_ALKHANI"] = "ANDREW_ALKHANI";
    Employee["ANNABELLE_LEAL"] = "ANNABELLE_LEAL";
    Employee["ARINDAM_BOSE"] = "ARINDAM_BOSE";
    Employee["ARYAN_ABED_ESFAHANI"] = "ARYAN_ABED_ESFAHANI";
    Employee["ARYAN_SALAM"] = "ARYAN_SALAM";
    Employee["AUGUSTE_GOODELL"] = "AUGUSTE_GOODELL";
    Employee["AVEN_INFRA"] = "AVEN_INFRA";
    Employee["AVEN_TRIGGERS"] = "AVEN_TRIGGERS";
    Employee["BEN_TRAN"] = "BEN_TRAN";
    Employee["BRETT_DRINGMAN"] = "BRETT_DRINGMAN";
    Employee["BRIEH_QUEJAYCO"] = "BRIEH_QUEJAYCO";
    Employee["CARLO_BALAIS"] = "CARLO_BALAIS";
    Employee["CARMINA_SALUD"] = "CARMINA_SALUD";
    Employee["CHARMAINE_BUNO"] = "CHARMAINE_BUNO";
    Employee["COLLEEN_BRUNNER"] = "COLLEEN_BRUNNER";
    Employee["COLLIN_WIKMAN"] = "COLLIN_WIKMAN";
    Employee["CONNOR_MCKEON"] = "CONNOR_MCKEON";
    Employee["DANIEL_BURKE"] = "DANIEL_BURKE";
    Employee["DANIEL_VENTURA"] = "DANIEL_VENTURA";
    Employee["DARCY_WARBURTON"] = "DARCY_WARBURTON";
    Employee["DAVI_COSTA"] = "DAVI_COSTA";
    Employee["DEANNA_LYNN_EARL"] = "DEANNA_LYNN_EARL";
    Employee["DEIRDRE_REILLY"] = "DEIRDRE_REILLY";
    Employee["DIMITRA_QUINTANILLA"] = "DIMITRA_QUINTANILLA";
    Employee["DINO_BECIROVIC"] = "DINO_BECIROVIC";
    Employee["DIVINE_BALANE"] = "DIVINE_BALANE";
    Employee["ELIEZER_PAREDES"] = "ELIEZER_PAREDES";
    Employee["ELIZABETH_OSTLER"] = "ELIZABETH_OSTLER";
    Employee["ELLA_EDRADAN"] = "ELLA_EDRADAN";
    Employee["EMANUEL_THOMPSON"] = "EMANUEL_THOMPSON";
    Employee["ENG"] = "ENG";
    Employee["GABRIEL_DE_LA_ESPERANZA"] = "GABRIEL_DE_LA_ESPERANZA";
    Employee["GALLANT_CHEN"] = "GALLANT_CHEN";
    Employee["GRACE_BAUTRO"] = "GRACE_BAUTRO";
    Employee["GRAHAM_DAVID_POWELL"] = "GRAHAM_DAVID_POWELL";
    Employee["HANXIN_JIN"] = "HANXIN_JIN";
    Employee["HIMANSHU_GUSAIN"] = "HIMANSHU_GUSAIN";
    Employee["HUI_CHEN"] = "HUI_CHEN";
    Employee["JAGDIP_SINGH"] = "JAGDIP_SINGH";
    Employee["JAWWAD_ZAKARIA"] = "JAWWAD_ZAKARIA";
    Employee["JAY_DONG"] = "JAY_DONG";
    Employee["JEFF_KRAUS"] = "JEFF_KRAUS";
    Employee["JEFFREY_JIMENEZ"] = "JEFFREY_JIMENEZ";
    Employee["JEN_WIBOWO"] = "JEN_WIBOWO";
    Employee["JENINA_CRISOLOGO"] = "JENINA_CRISOLOGO";
    Employee["JENNYLYN_DACARA"] = "JENNYLYN_DACARA";
    Employee["JERMAINE_KIMBLE"] = "JERMAINE_KIMBLE";
    Employee["JERMIE_LIBUNGAN"] = "JERMIE_LIBUNGAN";
    Employee["JIAYANG_TONG"] = "JIAYANG_TONG";
    Employee["JOSE_HERNANDEZ"] = "JOSE_HERNANDEZ";
    Employee["JOSH_GARDUCE"] = "JOSH_GARDUCE";
    Employee["JOSH_RINCON"] = "JOSH_RINCON";
    Employee["JOSH_SMITH"] = "JOSH_SMITH";
    Employee["JOSHUA_MEYER"] = "JOSHUA_MEYER";
    Employee["JULIE_ANN_GEMAL"] = "JULIE_ANN_GEMAL";
    Employee["JULIUS_BRAZA"] = "JULIUS_BRAZA";
    Employee["JUSTIN_POLLARD"] = "JUSTIN_POLLARD";
    Employee["JUSTINE_ERALDO"] = "JUSTINE_ERALDO";
    Employee["KAITLYNN_LINDBO"] = "KAITLYNN_LINDBO";
    Employee["KEITH_CUETO"] = "KEITH_CUETO";
    Employee["KENNETH_BAILEY"] = "KENNETH_BAILEY";
    Employee["KORRY_THOMPSON"] = "KORRY_THOMPSON";
    Employee["KYLA_GALICIA"] = "KYLA_GALICIA";
    Employee["LILLY_LUO"] = "LILLY_LUO";
    Employee["LIZZ_BARCENAS"] = "LIZZ_BARCENAS";
    Employee["LORRIE_BILTOFT"] = "LORRIE_BILTOFT";
    Employee["LYDIA_WANG"] = "LYDIA_WANG";
    Employee["MARIBETH_ALCANTARA"] = "MARIBETH_ALCANTARA";
    Employee["MARICON_JAVIER"] = "MARICON_JAVIER";
    Employee["MARK_LIU"] = "MARK_LIU";
    Employee["MATHEW_BLACK"] = "MATHEW_BLACK";
    Employee["MEGAN_GARCIA"] = "MEGAN_GARCIA";
    Employee["MICHAEL_THIEM"] = "MICHAEL_THIEM";
    Employee["MITCH_VILLANERA"] = "MITCH_VILLANERA";
    Employee["MOESHA_PASSLEY"] = "MOESHA_PASSLEY";
    Employee["MOHAMMED_ABDULRAHMAN"] = "MOHAMMED_ABDULRAHMAN";
    Employee["MURTADA_SHAH"] = "MURTADA_SHAH";
    Employee["NANCY_RANCE_DIONISIO"] = "NANCY_RANCE_DIONISIO";
    Employee["NAVIN_MANI"] = "NAVIN_MANI";
    Employee["NEIL_LIU"] = "NEIL_LIU";
    Employee["NIC_W"] = "NIC_W";
    Employee["NICK_STEPHENSON"] = "NICK_STEPHENSON";
    Employee["NIKKI_BEAVER"] = "NIKKI_BEAVER";
    Employee["PATRICK_EVANS"] = "PATRICK_EVANS";
    Employee["PEARL_FETALCORIN"] = "PEARL_FETALCORIN";
    Employee["PONJ_ASUNCION"] = "PONJ_ASUNCION";
    Employee["RACHEL_BORG"] = "RACHEL_BORG";
    Employee["RAY_SABADO"] = "RAY_SABADO";
    Employee["RAYAN_RIZVI"] = "RAYAN_RIZVI";
    Employee["REENA_JOY_GALECIO"] = "REENA_JOY_GALECIO";
    Employee["RIAH_ROSALES"] = "RIAH_ROSALES";
    Employee["RICH_WALKER"] = "RICH_WALKER";
    Employee["RONALD_PERKOWSKI"] = "RONALD_PERKOWSKI";
    Employee["RONALD_S_CHILLEMI"] = "RONALD_S_CHILLEMI";
    Employee["RUI_ZHOU"] = "RUI_ZHOU";
    Employee["RUTH_MAY_TOLETNINO"] = "RUTH_MAY_TOLETNINO";
    Employee["SADI_KHAN"] = "SADI_KHAN";
    Employee["SARANSH_SHARMA"] = "SARANSH_SHARMA";
    Employee["SARO_VASUDEVAN"] = "SARO_VASUDEVAN";
    Employee["SATCHEL_YANCEY_SIEGEL"] = "SATCHEL_YANCEY_SIEGEL";
    Employee["SCOTT_KING"] = "SCOTT_KING";
    Employee["SEAN_DOLAN"] = "SEAN_DOLAN";
    Employee["SEBASTIAN_GONZALEZ"] = "SEBASTIAN_GONZALEZ";
    Employee["SHUSHAUNA_BACCAS"] = "SHUSHAUNA_BACCAS";
    Employee["STEPHEN_LILLETHUN"] = "STEPHEN_LILLETHUN";
    Employee["STEVEN_THURMAN"] = "STEVEN_THURMAN";
    Employee["TARIK_GRAHAM"] = "TARIK_GRAHAM";
    Employee["TIANYANG_ZHANG"] = "TIANYANG_ZHANG";
    Employee["TONY_FORINO"] = "TONY_FORINO";
    Employee["VAIBHAV_PURANIK"] = "VAIBHAV_PURANIK";
    Employee["VALERIE_KAY"] = "VALERIE_KAY";
    Employee["VANISSA_EVANGELISTA"] = "VANISSA_EVANGELISTA";
    Employee["WALI_SHAGHEL"] = "WALI_SHAGHEL";
    Employee["WILFREDO_OCIONES"] = "WILFREDO_OCIONES";
    Employee["WILLIAM_QIN"] = "WILLIAM_QIN";
    Employee["XIAOCHEN_LIU"] = "XIAOCHEN_LIU";
    Employee["XING_XU"] = "XING_XU";
    Employee["XUEMEI_LIU"] = "XUEMEI_LIU";
    Employee["YANG_PEI"] = "YANG_PEI";
    Employee["YIN_WANG"] = "YIN_WANG";
    Employee["ZEWEN_WU"] = "ZEWEN_WU";
    Employee["ISHAN_VAID"] = "ISHAN_VAID";
    Employee["USMAN_GHANI"] = "USMAN_GHANI";
    Employee["ZELA_FLORES"] = "ZELA_FLORES";
    Employee["OSWALDO_MARTINEZ"] = "OSWALDO_MARTINEZ";
})(Employee = exports.Employee || (exports.Employee = {}));
/** @TranslateToPython */
var KnownRunners;
(function (KnownRunners) {
    // Provider data runners
    KnownRunners["coreLogicKnownLandUseRunner"] = "coreLogicKnownLandUseRunner";
    // income verification runners
    KnownRunners["incomeVerificationInvalidPlaidResponseRunner"] = "incomeVerificationInvalidPlaidResponseRunner";
    KnownRunners["incomeVerificationWorkNumberPdrMismatchRunner"] = "incomeVerificationWorkNumberPdrMismatchRunner";
    KnownRunners["incomeVerificationWorkNumberPdrNegativeRunner"] = "incomeVerificationWorkNumberPdrNegativeRunner";
    KnownRunners["incomeVerificationDocTypeRunner"] = "incomeVerificationDocTypeRunner";
    // underwriting verification runners
    KnownRunners["underwritingIncompleteAppRunner"] = "underwritingIncompleteAppRunner";
    KnownRunners["underwritingNoDuplicateHardPullsRunner"] = "underwritingNoDuplicateHardPullsRunner";
    // account opening
    KnownRunners["accountCreationRunner"] = "accountCreationRunner";
    KnownRunners["postNotarizationToAcceptedRunner"] = "postNotarizationToAcceptedRunner";
    // post account opening runners
    KnownRunners["rescissionPeriodRunner"] = "rescissionPeriodRunner";
    KnownRunners["creditLimitIncreaseAppliedSuccessfullyRunner"] = "creditLimitIncreaseAppliedSuccessfullyRunner";
    KnownRunners["creditLimitIncreaseNotYetAppliedRunner"] = "creditLimitIncreaseNotYetAppliedRunner";
    KnownRunners["aprReductionRunner"] = "aprReductionRunner";
    KnownRunners["accountActivationRequirementRunner"] = "accountActivationRequirementRunner";
    KnownRunners["accountActivationRequirementStatusRunner"] = "accountActivationRequirementStatusRunner";
    KnownRunners["accountActivationCashOutStatusRunner"] = "accountActivationCashOutStatusRunner";
    KnownRunners["accountActivationFeesRunner"] = "accountActivationFeesRunner";
    KnownRunners["avenCreditCardActivationRunner"] = "avenCreditCardActivationRunner";
    KnownRunners["validTransactionRunner"] = "validTransactionRunner";
    KnownRunners["recordedLiensRunner"] = "recordedLiensRunner";
    KnownRunners["creditBalanceRefundRunner"] = "creditBalanceRefundRunner";
    KnownRunners["payoffQuoteRunner"] = "payoffQuoteRunner";
    // post notarization runners
    KnownRunners["experianSubcodeRunner"] = "experianSubcodeRunner";
    KnownRunners["notarySessionRecordingPlaybackRunner"] = "notarySessionRecordingPlaybackRunner";
    KnownRunners["missingNotarySessionRecordingRunner"] = "missingNotarySessionRecordingRunner";
    KnownRunners["applicantNotaryAssignmentCompletenessRunner"] = "applicantNotaryAssignmentCompletenessRunner";
    KnownRunners["notarizationsCompleteRunner"] = "notarizationsCompleteRunner";
    KnownRunners["engNotaryAvailabilityRunner"] = "engNotaryAvailabilityRunner";
    KnownRunners["opsNotaryAvailabilityRunner"] = "opsNotaryAvailabilityRunner";
    // payment and fee runners
    KnownRunners["installmentLoanFixedFinanceChargeRunner"] = "installmentLoanFixedFinanceChargeRunner";
    KnownRunners["amortizationLoanPlanIdRunner"] = "amortizationLoanPlanIdRunner";
    KnownRunners["installmentLoanIncorrectPaidOffStatusRunner"] = "installmentLoanIncorrectPaidOffStatusRunner";
    KnownRunners["noWrongThreeDayPaymentCommsRunner"] = "noWrongThreeDayPaymentCommsRunner";
    KnownRunners["noWrongZeroDayPaymentCommsRunner"] = "noWrongZeroDayPaymentCommsRunner";
    KnownRunners["noMissedThreeDayPaymentCommsRunner"] = "noMissedThreeDayPaymentCommsRunner";
    KnownRunners["noMissedZeroDayPaymentCommsRunner"] = "noMissedZeroDayPaymentCommsRunner";
    KnownRunners["autopayRunnerAutoPayAmount"] = "autopayRunnerAutoPayAmount";
    KnownRunners["autopayRunnerAutoPayTiming"] = "autopayRunnerAutoPayTiming";
    KnownRunners["autopayRunnerAutoPayEnabledPaymentDueChecked"] = "autopayRunnerAutoPayEnabledPaymentDueChecked";
    KnownRunners["autopayNoDeletedBankAccount"] = "autopayNoDeletedBankAccount";
    KnownRunners["paymentVerificationRunner"] = "paymentVerificationRunner";
    KnownRunners["cashOutRunner"] = "cashOutRunner";
    KnownRunners["delinquencyRunner"] = "delinquencyRunner";
    KnownRunners["recordingChargeRunner"] = "recordingChargeRunner";
    KnownRunners["simpleInterestLoanConversionRunner"] = "simpleInterestLoanConversionRunner";
    KnownRunners["reageEligibilityRunner"] = "reageEligibilityRunner";
    KnownRunners["delinquencyNoticeRunner"] = "delinquencyNoticeRunner";
    KnownRunners["bankruptcyCommunicationRunner"] = "bankruptcyCommunicationRunner";
    KnownRunners["stalePaymentCourtesyRunner"] = "stalePaymentCourtesyRunner";
    KnownRunners["avenDebtValidationLetterRunner"] = "avenDebtValidationLetterRunner";
    KnownRunners["verifyDelinquencyStatusOnExperian"] = "verifyDelinquencyStatusOnExperian";
    // compliance runners
    KnownRunners["aanShouldBeSentButNotSentRunner"] = "aanShouldBeSentButNotSentRunner";
    KnownRunners["aanShouldNotBeSentButSentRunner"] = "aanShouldNotBeSentButSentRunner";
    KnownRunners["noiShouldBeSentButNotSentRunner"] = "noiShouldBeSentButNotSentRunner";
    KnownRunners["noiShouldNotBeSentButSentRunner"] = "noiShouldNotBeSentButSentRunner";
    KnownRunners["csdRunner"] = "csdRunner";
    KnownRunners["incompleteApplicationRunner"] = "incompleteApplicationRunner";
    KnownRunners["hmdaRunner"] = "hmdaRunner";
    KnownRunners["hmdaLarMissingApplicationsRunner"] = "hmdaLarMissingApplicationsRunner";
    KnownRunners["hmdaLarExtraApplicationsRunner"] = "hmdaLarExtraApplicationsRunner";
    KnownRunners["hoiRunner"] = "hoiRunner";
    KnownRunners["minimumSignerCountRunner"] = "minimumSignerCountRunner";
    KnownRunners["loanStatusMatchesCoreCard"] = "loanStatusMatchesCoreCard";
    // credit policy runners
    KnownRunners["creditPolicyPricingFicoRange"] = "creditPolicyPricingFicoRange";
    KnownRunners["creditPolicyVantageRange"] = "creditPolicyVantageRange";
    KnownRunners["creditPolicyPostLineDTI"] = "creditPolicyPostLineDTI";
    KnownRunners["creditPolicyPricingFicoLessThan700"] = "creditPolicyPricingFicoLessThan700";
    KnownRunners["creditPolicyPricingFicoBetween700And720"] = "creditPolicyPricingFicoBetween700And720";
    KnownRunners["creditPolicyPricingFicoBetween720And760"] = "creditPolicyPricingFicoBetween720And760";
    KnownRunners["creditPolicyPricingFicoBetween760And850"] = "creditPolicyPricingFicoBetween760And850";
    KnownRunners["creditPolicyLineSizePostLineCltv"] = "creditPolicyLineSizePostLineCltv";
    KnownRunners["creditPolicyPricingFicoPostLineCltv"] = "creditPolicyPricingFicoPostLineCltv";
    KnownRunners["creditPolicyNoThirdLiens"] = "creditPolicyNoThirdLiens";
    KnownRunners["creditPolicyNoHighCostMortgageRecord"] = "NoHighCostMortgageRecord";
    KnownRunners["creditPolicyNextLienPositionLargerThanOneHomeBalanceCannotBeZero"] = "creditPolicyNextLienPositionLargerThanOneHomeBalanceCannotBeZero";
    //
    // Underwriting spec based credit policy runners on data in factUnderwriting
    //
    // Runner that covers FICO/Vantage related elements in underwriting.
    KnownRunners["UwPolicyCreditScoreRunner"] = "UwPolicyCreditScoreRunner";
    // Runner that covers DTI related elements in underwriting.
    KnownRunners["UwPolicyDtiRunner"] = "UwPolicyDtiRunner";
    // Runner that covers CLTV related elements in underwriting.
    KnownRunners["UwPolicyCltvRunner"] = "UwPolicyCtlvRunner";
    // Runner that covers hardcuts.
    KnownRunners["UwPolicyHardcutsRunner"] = "UwPolicyHardcutsRunner";
    // Runner that covers APR floor/cap, and APR adders
    KnownRunners["UwPolicyAprRunner"] = "UwPolicyAprRunner";
    // Runner that covers line size floor/cap, and line size constraints
    KnownRunners["UwPolicyLineSizeRunner"] = "UwPolicyLineSizeRunner";
    // Runner that covers income and income verification in underwriting.
    KnownRunners["UwPolicyIncomeRunner"] = "UwPolicyIncomeRunner";
    // Runner that covers property in underwriting.
    KnownRunners["UwPolicyPropertyRunner"] = "UwPolicyPropertyRunner";
    // Runner that covers other elements in underwriting.
    KnownRunners["UwPolicyOthersRunner"] = "UwPolicyOthersRunner";
    // // Runner that covers human investigation checks.
    // UwPolicyHumanInvestigateRunner = 'UwPolicyHumanInvestigateRunner',
    // TODOs: After migrating naming matching from aven_backend to aven_python, followings are doable.
    // Runner that covers the matching between property and applicants
    // UwPolicyPropertyApplicantMatchingRunner = 'UwPolicyPropertyApplicantMatchingRunner',
    // Income verification runners
    KnownRunners["IncomeVerificationAllMethodsActiveRunner"] = "IncomeVerificationAllMethodsActiveRunner";
    KnownRunners["IncomeVerificationNoStuckApplicantsRunner"] = "IncomeVerificationNoStuckApplicantsRunner";
    // Compliance runners
    KnownRunners["unsupportedStatesRunner"] = "unsupportedStatesRunner";
    KnownRunners["maxLineSizeRunner"] = "maxLineSizeRunner";
    KnownRunners["lifeOfLoanMonitoringRunner"] = "lifeOfLoanMonitoringRunner";
    KnownRunners["cipVerificationRunner"] = "cipVerificationRunner";
    KnownRunners["cashoutBankVerificationRunner"] = "cashoutBankVerificationRunner";
    KnownRunners["noOriginationFeeInStatesThatDisallowOriginationFeesRunner"] = "noOriginationFeeInStatesThatDisallowOriginationFeesRunner";
    // Flood runners
    KnownRunners["floodRunner"] = "floodRunner";
    KnownRunners["floodDisclosureAcknowledgementRunner"] = "floodDisclosureAcknowledgementRunner";
    KnownRunners["notaryFloodWithoutDocsRunner"] = "notaryFloodWithoutDocsRunner";
    KnownRunners["notaryFloodWithUnknownZoneRunner"] = "notaryFloodWithUnknownZoneRunner";
    KnownRunners["floodRenewal45DayReminderRunner"] = "floodRenewal45DayReminderRunner";
    KnownRunners["floodRenewal15DayReminderRunner"] = "floodRenewal15DayReminderRunner";
    KnownRunners["floodForcePlacementRunner"] = "floodForcePlacementRunner";
    KnownRunners["clFloodErrorRateAlert"] = "clFloodErrorRateAlert";
    KnownRunners["floodContingencyRunner"] = "floodContingencyRunner";
    // Insurance Contingency runners
    KnownRunners["titleInsuranceContingencyRunner"] = "titleInsuranceContingencyRunner";
    KnownRunners["finalTitlePolicyReceivedRunner"] = "finalTitlePolicyReceivedRunner";
    KnownRunners["hazardInsuranceContingencyRunner"] = "hazardInsuranceContingencyRunner";
    KnownRunners["blockApplicablePastDueAccountsRunner"] = "blockApplicablePastDueAccountsRunner";
    KnownRunners["employmentVerificationContingencyRunner"] = "employmentVerificationContingencyRunner";
    KnownRunners["mlsListingContingencyRunner"] = "mlsListingContingencyRunner";
    // Comms runners
    KnownRunners["commsAdcOverrideRunner"] = "commsAdcOverrideRunner";
    KnownRunners["commsPostNotarizationDocsRunner"] = "commsPostNotarizationDocsRunner";
    KnownRunners["commsWelcomeRunner"] = "commsWelcomeRunner";
    KnownRunners["commsPreQualDripRunner"] = "commsPreQualDripRunner";
    KnownRunners["commsBadDripRunner"] = "commsBadDripRunner";
    KnownRunners["commsVoicemailRunner"] = "commsVoicemailRunner";
    KnownRunners["commsOutboundEmailRunner"] = "commsOutboundEmailRunner";
    KnownRunners["commsDataIntegrityRunner"] = "commsDataIntegrityRunner";
    KnownRunners["commsIncomeVerificationDripRunner"] = "commsIncomeVerificationDripRunner";
    KnownRunners["commsOfferedDripRunner"] = "commsOfferedDripRunner";
    KnownRunners["statementCommsRunner"] = "statementCommsRunner";
    KnownRunners["statementDownloadRunner"] = "statementDownloadRunner";
    KnownRunners["facebookPixelRunner"] = "facebookPixelRunner";
    KnownRunners["facebookUtmRunner"] = "facebookUtmRunner";
    KnownRunners["googleTagManagerRunner"] = "googleTagManagerRunner";
    KnownRunners["googleUtmRunner"] = "googleUtmRunner";
    KnownRunners["tiktokPixelRunner"] = "tiktokPixelRunner";
    KnownRunners["creditCardCustomerSmsReachabilityRunner"] = "creditCardCustomerSmsReachabilityRunner";
    KnownRunners["applicantSmsReachabilityRunner"] = "applicantSmsReachabilityRunner";
    KnownRunners["leadSmsReachabilityRunner"] = "leadSmsReachabilityRunner";
    KnownRunners["delinquencyCommsRunner"] = "delinquencyCommsRunner";
    KnownRunners["twilioDeliverabilityRunner"] = "twilioDeliverabilityRunner";
    KnownRunners["twilioBlockedPhoneNumbersRunner"] = "twilioBlockedPhoneNumbersRunner";
    KnownRunners["noSmsOnceOptedOutRunner"] = "noSmsOnceOptedOutRunner";
    KnownRunners["noEmailOnceOptedOutRunner"] = "noEmailOnceOptedOutRunner";
    KnownRunners["noRvmOnceOptedOutRunner"] = "noRvmOnceOptedOutRunner";
    KnownRunners["noCallOnceOptedOutRunner"] = "noCallOnceOptedOutRunner";
    KnownRunners["smsOptOutInstructionsRunner"] = "smsOptOutInstructionsRunner";
    KnownRunners["stopCommsOnRequestRunner"] = "stopCommsOnRequestRunner";
    KnownRunners["zendeskCommentCreatesCommunicationRunner"] = "zendeskCommentCreatesCommunicationRunner";
    KnownRunners["automatedOutboundCommunicationCreatesZendeskCommentRunner"] = "automatedOutboundCommunicationCreatesZendeskCommentRunner";
    KnownRunners["staleCommunicationRunner"] = "staleCommunicationRunner";
    KnownRunners["commsSentWithinLegalHoursRunner"] = "commsSentWithinLegalHoursRunner";
    KnownRunners["commsMissingChunksRunner"] = "commsMissingChunksRunner";
    // Mobile app runners
    KnownRunners["pushNotificationRegistrationRunner"] = "pushNotificationRegistrationRunner";
    KnownRunners["pushNotificationPublishRunner"] = "pushNotificationPublishRunner";
    KnownRunners["avenMyLoginRunner"] = "avenMyLoginRunner";
    // Account remediation runners
    KnownRunners["simpleInterestPlanRemediationRunner"] = "simpleInterestPlanRemediationRunner";
    // Data integrity runners
    KnownRunners["cardHolderDataIntegrityAvenStatementRunner"] = "cardHolderDataIntegrityAvenStatementRunner";
    KnownRunners["cardHolderDataIntegrityNextStatementDateRunner"] = "cardHolderDataIntegrityNextStatementDateRunner";
    KnownRunners["cardHolderDataIntegrityAvenBalanceTransferRunner"] = "cardHolderDataIntegrityAvenBalanceTransferRunner";
    KnownRunners["cardHolderDataIntegrityAvenInstantBalanceTransferRunner"] = "cardHolderDataIntegrityAvenInstantBalanceTransferRunner";
    KnownRunners["cardHolderDataIntegrityAvenBalanceTransferAccountStatusRunner"] = "cardHolderDataIntegrityAvenBalanceTransferAccountStatusRunner";
    KnownRunners["cardHolderDataIntegrityAccountClosureRequestRunner"] = "cardHolderDataIntegrityAccountClosureRequestRunner";
    KnownRunners["cardHolderDataIntegrityDqAccountChargeOffRunner"] = "cardHolderDataIntegrityDqAccountChargeOffRunner";
    KnownRunners["cardHolderDataIntegrityDqMetricsRecords"] = "cardHolderDataIntegrityDqMetricsRecords";
    KnownRunners["cardHolderDataIntegrityRunnerDqUnifiedDebtProductEntityRecords"] = "cardHolderDataIntegrityRunnerDqUnifiedDebtProductEntityRecords";
    KnownRunners["cardHolderDataIntegrityClosedAccountBalanceRunner"] = "cardHolderDataIntegrityClosedAccountBalanceRunner";
    KnownRunners["cardHolderDataIntegrityAvenRewardsTransactionsMatchDimTransactionsAndRewardsRunner"] = "cardHolderDataIntegrityAvenRewardsTransactionsMatchDimTransactionsAndRewardsRunner";
    KnownRunners["cardHolderDataIntegrityAvenRewardsRedemptionsSucceedRunner"] = "cardHolderDataIntegrityAvenRewardsRedemptionsSucceedRunner";
    KnownRunners["portfolioAllocationDataIntegrityRunner"] = "portfolioAllocationDataIntegrityRunner";
    KnownRunners["cardHolderTransactionsForUnActivatedCards"] = "cardHolderTransactionsForUnActivatedCards";
    // Legal doc runners
    KnownRunners["accountAgreementDocRunner"] = "accountAgreementDocRunner";
    KnownRunners["adverseActionNoticeContentRunner"] = "adverseActionNoticeContentRunner";
    KnownRunners["propertyValuationDocumentContentRunner"] = "propertyValuationDocumentContentRunner";
    KnownRunners["appraisalWaiverDocumentContentRunner"] = "appraisalWaiverDocumentContentRunner";
    KnownRunners["deedOfTrustContentRunner"] = "deedOfTrustContentRunner";
    KnownRunners["earlyHELOCDisclosureContentRunner"] = "earlyHELOCDisclosureContentRunner";
    // data feed runner
    KnownRunners["coreCardDataFeedDelayedRunner"] = "coreCardDataFeedDelayedRunner";
    // Capital markets runners
    KnownRunners["servicingDataTapeMissingStatementsRunner"] = "servicingDataTapeMissingStatementsRunner";
    KnownRunners["servicingDataTapeExtraStatementsRunner"] = "servicingDataTapeExtraStatementsRunner";
    KnownRunners["summaryDataTapeMissingAccountsRunner"] = "summaryDataTapeMissingAccountsRunner";
    KnownRunners["summaryDataTapeExtraAccountsRunner"] = "summaryDataTapeExtraAccountsRunner";
    KnownRunners["summaryDataTapeDuplicateAccountsRunner"] = "summaryDataTapeDuplicateAccountsRunner";
    KnownRunners["summaryDataTapeStaleAccountsRunner"] = "summaryDataTapeStaleAccountsRunner";
    KnownRunners["summaryDataTapeDataIntegrityRunner"] = "summaryDataTapeDataIntegrityRunner";
    // max APR runners
    KnownRunners["fixedTermLoanAprHcmLimitCheckRunner"] = "fixedTermLoanAprHcmLimitCheckRunner";
    KnownRunners["aprExceedGlobalLegalLimitRunner"] = "aprExceedGlobalLegalLimitRunner";
    // fee runner (balance transfer, cash out, origination)
    KnownRunners["feeRunner"] = "feeRunner";
    // UI runners
    KnownRunners["originationLandingPageRunner"] = "landingPageRunner";
    KnownRunners["originationPersonalInfoPageRunner"] = "personalInfoPageRunner";
    KnownRunners["originationFlowFootnotesRunner"] = "originationFlowFootnotesRunner";
    KnownRunners["avenMyAccountDetailsFootnotesRunner"] = "avenMyAccountDetailsFootnotesRunner";
    // Marketing Emails runners
    KnownRunners["marketingEmailUnsubscribeRunner"] = "marketingEmailUnsubscribeRunner";
    // Logs runners
    KnownRunners["cloudwatchAllNewSessionsRunner"] = "cloudwatchAllNewSessionsRunner";
    KnownRunners["illinoisAntiPredatoryContingencyRunner"] = "illinoisAntiPredatoryContingencyRunner";
    // Churn retention runners
    KnownRunners["mofoTreatmentAppliedSuccessfullyRunner"] = "mofoTreatmentAppliedSuccessfullyRunner";
    KnownRunners["lienAttestationRunner"] = "lienAttestationRunner";
})(KnownRunners = exports.KnownRunners || (exports.KnownRunners = {}));
